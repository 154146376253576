import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@shfortawesome/pro-light-svg-icons";
import { Badge } from "@streets-heaver/shui2";
import { getAppointmentCountdown } from "./getAppointmentCountdown";

export function getCountdownBadge(currentDateTime, start, end, status) {
	let badgeColour = "";
	let badgeText = "";
	const countdown = getAppointmentCountdown(start, currentDateTime);
	const beforeToday = start.startOf("day").diff(currentDateTime.startOf("day"), "day") < 0;
	const isDueOrDNA = status === "Booked" || status === "DNA";
	const appointmentHasEnded = currentDateTime > end;

	if (beforeToday || (appointmentHasEnded && !isDueOrDNA)) {
		badgeColour = "informative";
		badgeText = "Past";
	} else if (appointmentHasEnded && isDueOrDNA) {
		badgeColour = "danger";
		badgeText = "Late";
	} else if (start < currentDateTime && !appointmentHasEnded) {
		badgeColour = "success";
		badgeText = "Now";
	} else if (countdown.hours > 24) {
		badgeColour = "informative";
		badgeText = "in >1d";
	} else if (countdown.hours) {
		badgeColour = "informative";
		badgeText = countdown.minutes ? `in ${countdown.hours}h ${countdown.minutes}m` : `in ${countdown.hours}h`;
	} else if (countdown.minutes > 30) {
		badgeColour = "informative";
		badgeText = `in ${countdown.minutes}m`;
	} else if (countdown.minutes <= 15) {
		badgeColour = "danger";
		badgeText = `in ${countdown.minutes}m`;
	} else if (countdown.minutes <= 30) {
		badgeColour = "severeWarning";
		badgeText = `in ${countdown.minutes}m`;
	}

	return (
		<Badge
			icon={<FontAwesomeIcon icon={faClock} style={{ height: 12, width: 12 }} />}
			text={badgeText}
			size="medium"
			type="tint"
			color={badgeColour}
			style={{ padding: "3px 6px 3px 4px" }}
		/>
	);
}
