import { PatientCardPatientView } from "../../PatientCard/PatientCard";
import { useNextAppointmentWidget } from "../../../api/hooks/widgets/useNextAppointmentWidget";
import { ComingSoon } from "../ComingSoon/ComingSoon";
import { Widget } from "../Widget/Widget";
import { FEATURE_FLAGS } from "../../../globals/featureFlags";
import classes from "./NextAppointment.module.scss";
import { useVersionGate } from "@streets-heaver/core";
import { faCalendar } from "@shfortawesome/pro-duotone-svg-icons";

export const NextAppointment = ({ patientId }) => {
	const nextAppointment = useNextAppointmentWidget(patientId);
	const showNextAppointmentWidget = useVersionGate(FEATURE_FLAGS.NEXT_APPOINTMENT_FOR_PATIENT);

	return (
		<Widget>
			{showNextAppointmentWidget ? (
				returnNextAppointment(nextAppointment)
			) : (
				<ComingSoon title="Next appointment" icon={faCalendar} colour="var(--foregroundBrandOne)" />
			)}
		</Widget>
	);
};

const returnNextAppointment = (query) => {
	if (query.error)
		return (
			<>
				<Widget.Title>Next appointment</Widget.Title>
				<Widget.Locked
					subtitle={"Error fetching next appointment for this patient."}
					icon={faCalendar}
					colour="var(--foregroundBrandOne)"
				/>
			</>
		);

	if (query.isLoading)
		return (
			<>
				<p className={classes.title}>Next appointment</p>
				<p className={classes.message}>Loading appointment...</p>
			</>
		);

	if (query.data) return <PatientCardPatientView ghost={query.isLoading} appointment={{ ...query.data, type: "OP" }} />;

	return (
		<>
			<Widget.Title>Next appointment</Widget.Title>
			<Widget.Locked
				subtitle={"No appointment currently booked."}
				icon={faCalendar}
				colour="var(--foregroundBrandOne)"
			/>
		</>
	);
};
