import { getIfEmptyOrNull, useFormError } from "../../../../../utils";
import { SegmentedControl, Textbox } from "@streets-heaver/shui2";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { preferredWrittenMethodOptions } from "../../../../../globals/defaultOptions";
import { PreferredNotification } from "./PreferredNotification";
import { AdditionalPhoneNumbers } from "./AdditionalPhoneNumbers";
import { Address } from "./Address/Address";
import {
	addressLine1ErrorText,
	addressLine1SchemaError,
	addressLine2SchemaError,
	addressLine3SchemaError,
	countySchemaError,
	emailErrorText,
	emailLengthSchemaError,
	emailSchemaError,
	mainSchemaError,
	mainSmsErrorText,
	mobileErrorText,
	mobileSchemaError,
	mobileSmsErrorText,
	postcodeErrorText,
	postcodeSchemaError,
	townCitySchemaError,
	workSchemaError,
} from "../../../../../globals/messages";
import { emailRegex, phoneNumberRegex } from "../../../../../globals/regex";

export const Contact = ({ automationId }) => {
	const form = useFormContext();

	const notificationMethodConfirmed = form.register("contact.primary.notificationMethodConfirmed");
	const preferredNotificationMethod = form.register("contact.primary.preferredNotificationMethod");
	const preferredWrittenMethod = form.register("contact.primary.preferredWrittenMethod");

	const preferredWrittenMethodField = useWatch({ name: preferredWrittenMethod.name });
	const preferredNotificationMethodField = useWatch({ name: preferredNotificationMethod.name });
	const notificationMethodConfirmedField = useWatch({ name: notificationMethodConfirmed.name });

	const isAddressMandatory = preferredWrittenMethodField === "Post";
	const isEmailMandatory =
		preferredWrittenMethodField === "Email" ||
		(notificationMethodConfirmedField && preferredNotificationMethodField === "3");

	const isMobileRequiredForCalls = notificationMethodConfirmedField && preferredNotificationMethodField === "4";
	const isMobileRequiredForSMS = notificationMethodConfirmedField && preferredNotificationMethodField === "2";

	const isMobilePhoneMandatory = isMobileRequiredForCalls || isMobileRequiredForSMS;
	const isMainPhoneMandatory = notificationMethodConfirmedField && preferredNotificationMethodField === "1";

	const lineOne = form.register("contact.primary.address.addressLine1", {
		maxLength: { value: 72, message: addressLine1SchemaError },
		validate: {
			requiredByWritten: (val) => !(isAddressMandatory && getIfEmptyOrNull(val)) || addressLine1ErrorText,
		},
	});
	const lineTwo = form.register("contact.primary.address.addressLine2", {
		maxLength: { value: 50, message: addressLine2SchemaError },
	});
	const lineThree = form.register("contact.primary.address.addressLine3", {
		maxLength: { value: 50, message: addressLine3SchemaError },
	});
	const townCity = form.register("contact.primary.address.addressLine4", {
		maxLength: { value: 50, message: townCitySchemaError },
	});
	const county = form.register("contact.primary.address.addressLine5", {
		maxLength: { value: 50, message: countySchemaError },
	});
	const postcode = form.register("contact.primary.address.postcode", {
		maxLength: { value: 10, message: postcodeSchemaError },
		validate: {
			requiredByWritten: (val) => !(isAddressMandatory && getIfEmptyOrNull(val)) || postcodeErrorText,
		},
	});
	const countryFieldName = "contact.primary.address.country";
	const email = form.register("contact.primary.email", {
		pattern: { value: emailRegex, message: emailSchemaError },
		maxLength: { value: 100, message: emailLengthSchemaError },
		validate: { requiredByWritten: (val) => !(isEmailMandatory && getIfEmptyOrNull(val)) || emailErrorText },
	});
	const mobilePhone = form.register("contact.primary.mobilePhone", {
		maxLength: { value: 30, message: mobileSchemaError },
		pattern: { value: phoneNumberRegex, message: mobileSchemaError },
		validate: {
			requiredByNotifCall: (val) => !(isMobileRequiredForCalls && getIfEmptyOrNull(val)) || mobileErrorText,
			requiredByNotifSMS: (val) => !(isMobileRequiredForSMS && getIfEmptyOrNull(val)) || mobileSmsErrorText,
		},
	});
	const workPhone = form.register("contact.primary.workPhone", {
		maxLength: { value: 30, message: workSchemaError },
		pattern: { value: phoneNumberRegex, message: workSchemaError },
	});
	const mainPhone = form.register("contact.primary.mainPhone", {
		maxLength: { value: 30, message: mainSchemaError },
		pattern: { value: phoneNumberRegex, message: mainSchemaError },
		validate: { requiredByNotif: (val) => !(isMainPhoneMandatory && getIfEmptyOrNull(val)) || mainSmsErrorText },
	});

	const address = form.register("contact.primary.address", {
		deps: [lineOne.name, postcode.name],
	});

	return (
		<div data-section id="contact">
			<FormGroup title="Contact">
				<Address
					isMandatory={isAddressMandatory}
					control={form.control}
					address={address}
					addressLine1={lineOne}
					addressLine2={lineTwo}
					addressLine3={lineThree}
					townCity={townCity}
					county={county}
					postcode={postcode}
					countryFieldName={countryFieldName}
					automationId={automationId}
				/>
				<FormField label="Email" name={email.name} isMandatory={isEmailMandatory}>
					<Textbox
						type={"filledDarker"}
						inputName={email.name}
						onChange={email.onChange}
						onBlur={email.onBlur}
						reference={email.ref}
						size="large"
						isError={useFormError(email.name).length > 0}
						automationId={`${automationId}-email`}
					/>
				</FormField>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Mobile Phone" name={mobilePhone.name} isMandatory={isMobilePhoneMandatory}>
						<Textbox
							type={"filledDarker"}
							inputName={mobilePhone.name}
							onChange={mobilePhone.onChange}
							onBlur={mobilePhone.onBlur}
							reference={mobilePhone.ref}
							size="large"
							isError={useFormError(mobilePhone.name).length > 0}
							automationId={`${automationId}-mobile`}
						/>
					</FormField>
				</div>

				<AdditionalPhoneNumbers
					mainPhoneField={useWatch({ name: mainPhone.name })}
					workPhoneField={useWatch({ name: workPhone.name })}
					workPhone={workPhone}
					mainPhone={mainPhone}
					isMainPhoneMandatory={isMainPhoneMandatory}
					hasMainPhoneError={useFormError(mainPhone.name).length > 0}
					hasWorkPhoneError={useFormError(workPhone.name).length > 0}
					automationId={automationId}
				/>

				<PreferredNotification
					notificationMethodConfirmed={notificationMethodConfirmed}
					preferredNotificationMethod={preferredNotificationMethod}
					mobilePhone={mobilePhone}
					email={email}
					mainPhone={mainPhone}
					automationId={automationId}
				/>

				<FormField label="Preferred written method" name={preferredWrittenMethod.name}>
					<div style={{ maxWidth: "240px" }}>
						<Controller
							name={preferredWrittenMethod.name}
							render={({ field: { value, onChange } }) => (
								<SegmentedControl
									onChange={(e) => {
										onChange(e).then(() => form.trigger([lineOne.name, postcode.name, email.name]));
									}}
									updateSelectedSegment={value}
									type="tabs"
									segments={preferredWrittenMethodOptions}
									automationId={`${automationId}-written-preferred`}
								/>
							)}
						/>
					</div>
				</FormField>
			</FormGroup>
		</div>
	);
};
