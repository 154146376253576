import { Datebox } from "@streets-heaver/shui2";
import { forwardRef } from "react";

export const FormDatebox = forwardRef(function FormDatebox(
	{ value, onChange, isError, portalTarget, automationId },
	ref,
) {
	return (
		<Datebox
			customWidth={160}
			size="large"
			type={"filledDarker"}
			isError={isError}
			automationId={automationId}
			calendarAutomationId={`${automationId}-calendar`}
			floatingPortalTarget={portalTarget}
			onSelected={(date) => date && onChange(date.toISOString())}
			showInitialDate={!!value}
			defaultDate={value && new Date(value)}
			ref={ref}
		/>
	);
});
