// Appointment Forms

export const patientLookupInvalidSchemaError = "Patient not within allowable genders or age ranges.";
export const patientLookupSchemaError = "A valid patient must be selected";
export const episodeOfCareSchemaError = "An episode of care must be selected.";
export const referralDatePastSchemaError = "Referral date must be in the past.";

export const bookingDateFutureSchemaError = "Booking date must be in the future.";
export const bookingTimeSchemaError = "Please select a booking time.";
export const bookingTimeInvalidError = "Please select a booking time from the dropdown.";
export const bookingDurationSchemaError = "A duration must be selected.";
export const siteSchemaError = "A site must be selected.";
export const clinicianAvailabilityError = "Error fetching availability from the API. Please try again.";

export const bookingTimePastRefinementError = "The booking time must be in the future.";

export const timeHelperText = "The time selected must be available from the dropdown menu in order to be valid.";
export const durationHelperText = "The durations available are calculated based on the time selected.";

export const dateErrorMessage = "The selected date does not have any availability. Please select a different date.";
export const timeErrorMessage =
	"The selected time is not within the selected date's availability. Please select a different time.";

export const contactTypeRefinementError = "Contact type is required when booking is not face to face.";

export const purchaserHelperText = "Purchaser can only be selected once a site has been selected.";
export const authCodeSchemaError = "Authorisation Code must be 50 characters or less.";
export const authCodeRefinementError = "Authorisation Code is required when an EDI or mandatory purchaser is selected.";
export const membershipNumberSchemaError = "Insurance Membership Number must be 40 characters or less.";
export const membershipNumberRefinementError =
	"Insurance membership number is required when an EDI or mandatory purchaser is selected.";

export const arrivedTimeHelperText =
	"Booking arrival time can only be set or edited on the date the booking is due to start.";

export const cancellationReasonSchemaError = "Cancellation reason is required";
export const outcomeSchemaError = "Outcome is required";

// Patient Forms

export const titleSchemaError = "Title must be 25 characters or less.";
export const surnameSchemaRequiredError = "Family name is required and cannot be left blank.";
export const surnameSchemaLengthError = "Family name must be 200 characters or less.";
export const forenameSchemaError = "Given name must be 200 characters or less.";

export const dateOfBirthPastSchemaError = "Date of birth must be in the past.";
export const genderSchemaError = "Gender is required. Please select a gender.";

export const addressLine1SchemaError = "Address Line 1 must be 72 characters or less.";
export const addressLine2SchemaError = "Address Line 2 must be 50 characters or less.";
export const addressLine3SchemaError = "Address Line 3 must be 50 characters or less.";
export const townCitySchemaError = "Town/City must be 50 characters or less.";
export const countySchemaError = "County must be 50 characters or less.";
export const postcodeSchemaError = "Postcode must be 10 characters or less.";

export const emailSchemaError = "Email is invalid.";
export const emailLengthSchemaError = "Email must be 100 characters or less.";
export const mobileSchemaError = "Mobile phone number is invalid.";
export const workSchemaError = "Work phone number is invalid.";
export const mainSchemaError = "Main phone number is invalid.";

export const preferredNotificationErrorText =
	"Preferred notification method is required. Please choose an option from the list.";
export const emailErrorText = "Email is required when it is chosen as the preferred notification / written method.";
export const addressLine1ErrorText = "Address Line 1 is required when post is chosen as the preferred written method.";
export const postcodeErrorText = "Postcode is required when post is chosen as the preferred written method.";
export const mobileErrorText = "Mobile phone is required when it is chosen as the preferred notification method.";
export const mobileSmsErrorText =
	"Mobile phone is required when SMS to mobile is chosen as the preferred notification method.";
export const mainSmsErrorText =
	"Main phone is required when SMS to main phone is chosen as the preferred notification method.";

export const caseNoSchemaError = "Case number must be 100 characters or less.";
export const nhsNoSchemaError = "NHS Number is not valid.";
export const hospitalIdSchemaError = "Hospital ID must be 50 characters or less.";

export const nhsNoErrorText = "NHS number cannot be blank when NHS number verify status declares it present.";

export const nokRelationshipSchemaError = "Relationship is required and cannot be left blank.";

// Print Forms

export const reportTemplateSchemaError = "A report template is required.";
