import classes from "./ContentSkeleton.module.scss";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { useEffect, useState } from "react";

const SHOW_DELAY = 1;
const WAIT_MESSAGE_DELAY = 30;

export const ContentSkeleton = () => {
	const [loadState, setLoadState] = useState(null);

	useEffect(() => {
		const showTimer = setTimeout(() => {
			setLoadState("show-icon");
		}, SHOW_DELAY * 1000);
		const waitMessageTimer = setTimeout(() => {
			setLoadState("wait-message");
		}, WAIT_MESSAGE_DELAY * 1000);

		return () => {
			clearTimeout(showTimer);
			clearTimeout(waitMessageTimer);
		};
	}, []);

	if (loadState === null) return;
	return (
		<div className={classes.contentSkeleton}>
			<LoadingSpinner className={classes.loadingIcon} />
			<p className={classes.text} style={{ visibility: loadState === "wait-message" ? "visible" : "hidden" }}>
				It’s taking longer than usual to load
			</p>
		</div>
	);
};
