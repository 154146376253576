import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../../globals/constants";

export const usePatientOrdersWidget = (patientId, take, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const enabledCriteria = !!currentDataSource && !!currentClinician && !!patientId && !isNaN(take) && enabled;

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["patientOrdersWidget", currentClinician, patientId],
		queryFn: async ({ pageParam }) => {
			const data = await getPatientOrders(
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				pageParam,
				take,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: enabledCriteria,
	});

	return query;
};

const getPatientOrders = async (dataSourceId, clinicianId, patientId, skip = 0, take) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/patient/${patientId}/widget/orders?skip=${skip}&take=${take + 1}`,
		{},
		"v2",
	);
	return {
		totalServiceRequests: data?.totalServiceRequests,
		data: data?.serviceRequests?.slice(0, take),
		nextPageIndex: data?.serviceRequests?.length >= take + 1 ? skip + take : undefined,
	};
};
