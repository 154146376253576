import classes from "./FormResultsHistoryModal.module.scss";
import { Sheet } from "@streetsheaver/compucore";
import { useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useRef } from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Label, Line, Tooltip } from "recharts";
import { useOrderFormResults } from "../../api/hooks/useOrderFormResults";
import { ExpandedRequestResultRow } from "../../components/RequestCard/RequestCard";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import { dateTimeFormat } from "../../utils";
import { ActionPaths } from "../../layouts/Layout/actionPaths";

export const FormResultsHistoryModal = () => {
	const { patientId, formComponentId } = useParams();
	const query = useOrderFormResults(patientId, formComponentId, 10);
	const navigate = useNavigate();

	const data = useMemo(
		() => ({
			...query?.data?.pages[0].data,
			results: query?.data?.pages.reduce((prev, curr) => prev.concat(curr.data.results), []),
		}),
		[query?.data],
	);

	const hasNumberResult = data?.results?.some((result) => typeof result?.result === "number");

	return (
		<Sheet visible onClose={() => navigate("..")} header={{ title: "Form Results History" }}>
			<div className={classes.modal}>
				<ResultsTable query={query} data={data} />
				{hasNumberResult && <ResultsChart data={data} />}
			</div>
		</Sheet>
	);
};

export const ResultsTable = ({ query, data }) => {
	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((elements) => {
			elements.forEach((element) => {
				if (element.isIntersecting && !query?.isFetching && query?.hasNextPage) {
					query?.fetchNextPage();
				}
			});
		});

		if (endOfListRef.current) observer.observe(endOfListRef.current);

		return () => {
			observer.disconnect();
		};
	}, [query]);

	return (
		<div className={classes.requestCard} data-testid={"results-history"}>
			<div className={classes.header}>
				<TruncatedText>{data?.name}</TruncatedText>
				{data?.completionDate && <p>{dateTimeFormat(data?.completionDate)}</p>}
			</div>
			<div className={classes.body}>
				{data?.results?.map((result, i) => (
					<ExpandedRequestResultRow
						result={result}
						to={ActionPaths.FormHistoryResults(result?.formComponentId)}
						key={`request-${i}`}
					/>
				))}
				<div ref={endOfListRef} />
				{query?.hasNextPage && <ExpandedRequestResultRow ghost />}
			</div>
		</div>
	);
};

export const ResultsChart = ({ data }) => {
	const defaultValue = data?.results?.[0];

	const textFill = { fill: "var(--foregroundTwo)" };

	const tickFormatter = (tickFormat) => {
		return dateTimeFormat(tickFormat);
	};

	return (
		<div className={classes.resultsChart}>
			<h2 className={classes.title}>Charted data</h2>
			<ResponsiveContainer width="100%" minHeight={320}>
				<LineChart data={data?.results}>
					<CartesianGrid vertical={false} strokeDasharray="0" stroke="var(--strokeOne)" fill={"var(--backgroundTwo)"} />
					<XAxis padding={{ left: 40, right: 40 }} tick={textFill} tickFormatter={tickFormatter} dataKey="createDate">
						<Label position="bottom" offset={-6} style={textFill}>
							Created Date
						</Label>
					</XAxis>
					<YAxis tick={textFill}>
						<Label position="left" angle={-90} offset={-6} style={textFill}>{`Value (${defaultValue?.units})`}</Label>
					</YAxis>
					<Line type="monotone" dataKey="result" stroke="var(--Compucare)" />
					<Tooltip content={<CustomTooltip />} />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div className={classes.tooltip}>
				<p>Created data: {dateTimeFormat(label)}</p>
				<p>
					Result: {payload[0]?.value}
					{payload[0]?.payload?.units}
				</p>
			</div>
		);
	}

	return null;
};
