import { Dialog } from "@streets-heaver/shui2";
import { useLocation, useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import { NoAccessMessage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { usePrintList } from "../../api/hooks";
import { PrintForm } from "../../components/FormComponents";
import { transformPrintData } from "./transformPrintData";

export const PrintModal = ({ type }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { userSecurityFlags } = useCurrentClinicians();
	const { mutateAsync: printList, status: printStatus } = usePrintList(type);
	const automationId = "print-lists";

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { startDate: new Date(), endDate: new Date(), site: null, template: null },
	});

	const onClose = () => navigate({ pathname: "..", search: location.search });

	const onSubmit = (data) => {
		toast
			.promise(printList(transformPrintData(data)), {
				loading: {
					title: "Queuing report to print",
					subtitle: `Queuing report for ${data?.site?.name}`,
				},
				success: {
					title: "Successfully queued report",
					subtitle: "Report queued",
				},
				error: (e) => ({
					title: "Error queuing report",
					subtitle: e?.response?.data?.detail ?? `Failed queuing report. Please try again later.`,
				}),
			})
			.then(onClose);
	};

	if (!userSecurityFlags?.includes(`toolbar-printing`))
		return (
			<Dialog
				visible
				fixedHeight={"100px"}
				header="Print lists"
				onCloseButtonClick={onClose}
				onOutsideClick={onClose}
				buttons={{
					primaryButton: { children: "Print", disabled: true },
					secondaryButton: { children: "Cancel", onClick: onClose },
				}}
			>
				<NoAccessMessage />
			</Dialog>
		);

	return (
		<Dialog
			visible
			header="Print lists"
			onCloseButtonClick={onClose}
			onOutsideClick={onClose}
			buttons={{
				primaryButton: {
					children: "Print",
					onClick: methods.handleSubmit(onSubmit),
					disabled: printStatus === "pending",
					automationId: `${automationId}-submit`,
				},
				secondaryButton: { children: "Cancel", onClick: onClose, automationId: `${automationId}-cancel` },
			}}
		>
			<FormProvider {...methods}>
				<PrintForm type={type} automationId={automationId} />
			</FormProvider>
		</Dialog>
	);
};
