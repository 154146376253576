import { FormGroup } from "../../../FormGroup/FormGroup";
import { CancellationReasonsLookup, OutcomesLookup } from "../../../lookups";

export const Cancel = ({ isEdit, automationId }) => {
	return (
		<FormGroup title={isEdit && "Cancel"}>
			<CancellationReasonsLookup isMandatory automationId={automationId} />
			<OutcomesLookup isMandatory automationId={automationId} />
		</FormGroup>
	);
};
