import { nhsVerifyStatusOptions } from "../../globals/defaultOptions";

export const addPatientFormDefaultValues = () => ({
	name: {
		primary: {
			title: "",
			forename: "",
			surname: "",
		},
	},
	identity: {
		dateOfBirth: null,
		gender: null,
	},
	contact: {
		primary: {
			address: {
				addressLine1: "",
				addressLine2: "",
				addressLine3: "",
				addressLine4: "",
				addressLine5: "",
				postcode: "",
				country: null,
			},
			email: "",
			mobilePhone: "",
			workPhone: "",
			mainPhone: "",
			notificationMethodConfirmed: false,
			preferredNotificationMethod: null,
			preferredWrittenMethod: "Post",
		},
	},
	nextOfKin: [],
	sharedDetails: {
		registeredGP: null,
		gpPractice: null,
		registeredOptician: null,
		opticianPractice: null,
	},
	identifiers: {
		caseNo: "",
		nhsNo: "",
		hospitalId: "",
		verificationStatus: nhsVerifyStatusOptions[2],
		nhsTraceDate: null,
	},
});
