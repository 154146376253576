import { usePatientsLookup } from "../../../api/hooks";
import { useFormError } from "../../../utils";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { PatientLookup } from "@streetsheaver/compucore";
import { patientLookupInvalidSchemaError, patientLookupSchemaError } from "../../../globals/messages";

export const PatientsLookup = ({ label = "Patient", name = "patient", isMandatory, automationId }) => {
	const { control } = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = usePatientsLookup(searchTerm);

	const error = useFormError(name);

	return (
		<FormField label={label} name={name} isMandatory={isMandatory}>
			<Controller
				name={name}
				control={control}
				rules={{
					validate: {
						required: (value) => value || patientLookupSchemaError,
						allowableBookings: (value) =>
							(value && value?.canMakeBooking) || (value && patientLookupInvalidSchemaError),
					},
				}}
				render={({ field: { value, onChange, ref } }) => (
					<PatientLookup
						selected={value}
						patientLinkPrefix={"../patient-area/"}
						onSearchTermChange={setSearchTerm}
						setSelected={onChange}
						searchQueryData={query}
						reference={ref}
						isError={error?.length > 0}
						automationId={`${automationId}-patient`}
					/>
				)}
			/>
		</FormField>
	);
};
