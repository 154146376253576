import { Button, Tooltips } from "@streets-heaver/shui2";
import classes from "./ServiceRequestRow.module.scss";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import { Link } from "../../../Link/Link";
import { SeenRequest } from "../BadgeCells/BadgeCells";
import { shortDateFormat } from "../../../../utils/dateFormatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faDownload } from "@shfortawesome/pro-light-svg-icons";
import { faDownload as farDownload } from "@shfortawesome/pro-regular-svg-icons";
import { faDownload as fasDownload } from "@shfortawesome/pro-solid-svg-icons";
import { useCurrentClinicians } from "../../../../api/hooks/useClinicians";
import { useUpdateOrderStatus } from "../../../../api/hooks/useUpdateOrderStatus";
import { useNavigate } from "react-router";
import { createPortal } from "react-dom";
import clsx from "clsx";

export const ServiceRequestRow = ({ request, resultCallback, showShortButtons }) => {
	const width = useContentSizeClass();
	const { userSecurityFlags } = useCurrentClinicians();
	const isSeen = request?.status === "Seen";
	const isSentOrSeen = isSeen || request?.status === "Sent";

	const navigate = useNavigate();
	const { mutate: markAsSeen, isPending: isMarkAsSeenPending } = useUpdateOrderStatus(isSeen ? "sent" : "seen");

	return (
		<div
			className={classes.requestRow}
			style={{ backgroundColor: showShortButtons ? "unset" : "var(--backgroundTwo)" }}
			data-testid="request-row"
		>
			<div
				className={clsx(
					classes.info,
					!showShortButtons && width < ScreenSize.TabletPortrait ? classes.infoFlex : classes.infoGrid,
				)}
			>
				<div className={classes.titleInfo}>
					<p className={classes.title} data-testid="request-name">
						{request?.serviceRequestName}
					</p>
					{getStatusIcon(request)}
					<SeenRequest isRead={isSeen || isMarkAsSeenPending} />
				</div>
				{request?.verifiedDate && (
					<p className={classes.reportVerified}>Report verified {shortDateFormat(request?.verifiedDate)}</p>
				)}
			</div>
			<div
				className={classes.actions}
				style={{ width: showShortButtons ? "106px" : width >= ScreenSize.TabletLandscape ? "350px" : "fit-content" }}
			>
				{width >= ScreenSize.TabletLandscape && (
					<>
						{!showShortButtons && isSentOrSeen && (
							<Button
								size="small"
								disabled={!userSecurityFlags.includes("orders-markseen") || isMarkAsSeenPending}
								onClick={(e) => {
									e.stopPropagation();
									markAsSeen(request?.orderId);
								}}
							>
								{isSeen ? "Mark as unseen" : "Mark as seen"}
							</Button>
						)}
						<Button
							size={showShortButtons ? "medium" : "small"}
							icon={{
								restIcon: faDownload,
								hoverIcon: farDownload,
								pressedIcon: fasDownload,
							}}
							disabled={
								!userSecurityFlags.includes(showShortButtons ? "patientview-ordersdownload" : "orders-download")
							}
							onClick={(e) => e.stopPropagation()}
						>
							{showShortButtons ? "" : "Download"}
						</Button>
					</>
				)}
				{width < ScreenSize.TabletLandscape && !showShortButtons && request.hasReport && (
					<Link
						text="Report"
						onClick={(e) => {
							e.stopPropagation();
							console.log("View report");
						}}
						icon={!showShortButtons}
					/>
				)}
				{!(width < ScreenSize.TabletLandscape && !showShortButtons) && request.hasResult && (
					<Link
						text="Results"
						onClick={(e) => {
							e.stopPropagation();
							navigate(resultCallback?.(request));
						}}
						icon={!showShortButtons}
					/>
				)}
			</div>
		</div>
	);
};

const getStatusIcon = (request) => {
	if (request?.reportable && !request?.hasReport) {
		return (
			<>
				<FontAwesomeIcon
					data-tooltipid={`serviceRequestRow-${request?.orderId}`}
					icon={faClock}
					style={{ color: "var(--backgroundMarigold)" }}
				/>
				{createPortal(
					<Tooltips
						tooltips={[
							{
								anchorSelect: `[data-tooltipid='serviceRequestRow-${request?.orderId}']`,
								content: <p>The order is pending because it requires a report.</p>,
								place: "bottom",
							},
						]}
					/>,
					document.body,
				)}
			</>
		);
	} else {
		return <FontAwesomeIcon icon={faCheck} style={{ color: "var(--foregroundGreen)" }} />;
	}
};
