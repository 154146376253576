import classes from "./NextOfKinForm.module.scss";
import { Dialog, Divider, Textbox, TextArea } from "@streets-heaver/shui2";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DiscardChangesDialog } from "../DiscardChangesDialog/DiscardChangesDialog";
import { addNextOfKinFormDefaultValues } from "./individualNextOfKinValues";
import { AdditionalPhoneNumbers } from "../PatientForms/Sections/Contact/AdditionalPhoneNumbers";
import { FormGroup } from "../FormGroup/FormGroup";
import { FormField } from "../FormField/FormField";
import { useFormError } from "../../../utils";
import { Address } from "../PatientForms/Sections/Contact/Address/Address";
import { Name } from "../PatientForms/Sections/Name/Name";
import { RelationshipsLookup } from "../lookups/RelationshipsLookup";
import { FormDatebox } from "../FormDatebox/FormDatebox";
import {
	titleSchemaError,
	surnameSchemaLengthError,
	surnameSchemaRequiredError,
	forenameSchemaError,
	dateOfBirthPastSchemaError,
	addressLine1SchemaError,
	addressLine2SchemaError,
	addressLine3SchemaError,
	townCitySchemaError,
	countySchemaError,
	postcodeSchemaError,
	emailSchemaError,
	emailLengthSchemaError,
	mobileSchemaError,
	workSchemaError,
	mainSchemaError,
} from "../../../globals/messages";
import dayjs from "dayjs";
import { emailRegex, phoneNumberRegex } from "../../../globals/regex";

export const NextOfKinForm = ({ visible, onDialogClose, onSubmit, defaultValues, type = "Add", automationId }) => {
	const [discardChangesVisible, setDiscardChangesVisible] = useState(false);
	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...addNextOfKinFormDefaultValues(), ...defaultValues },
	});

	const title = methods.register("name.title", { maxLength: { value: 25, message: titleSchemaError } });
	const surname = methods.register("name.surname", {
		required: { value: true, message: surnameSchemaRequiredError },
		maxLength: { value: 200, message: surnameSchemaLengthError },
	});
	const forename = methods.register("name.forename", {
		maxLength: { value: 200, message: forenameSchemaError },
	});
	const lineOne = methods.register("contact.address.addressLine1", {
		maxLength: { value: 72, message: addressLine1SchemaError },
	});
	const lineTwo = methods.register("contact.address.addressLine2", {
		maxLength: { value: 50, message: addressLine2SchemaError },
	});
	const lineThree = methods.register("contact.address.addressLine3", {
		maxLength: { value: 50, message: addressLine3SchemaError },
	});
	const townCity = methods.register("contact.address.addressLine4", {
		maxLength: { value: 50, message: townCitySchemaError },
	});
	const county = methods.register("contact.address.addressLine5", {
		maxLength: { value: 50, message: countySchemaError },
	});
	const postcode = methods.register("contact.address.postcode", {
		maxLength: { value: 10, message: postcodeSchemaError },
	});
	const countryFieldName = "contact.address.country";
	const email = methods.register("contact.email", {
		pattern: { value: emailRegex, message: emailSchemaError },
		maxLength: { value: 100, message: emailLengthSchemaError },
	});
	const mobilePhone = methods.register("contact.mobilePhone", {
		maxLength: { value: 30, message: mobileSchemaError },
		pattern: { value: phoneNumberRegex, message: mobileSchemaError },
	});
	const workPhone = methods.register("contact.workPhone", {
		maxLength: { value: 30, message: workSchemaError },
		pattern: { value: phoneNumberRegex, message: workSchemaError },
	});
	const mainPhone = methods.register("contact.mainPhone", {
		maxLength: { value: 30, message: mainSchemaError },
		pattern: { value: phoneNumberRegex, message: mainSchemaError },
	});
	const dateOfBirth = methods.register("dateOfBirth");
	const notes = methods.register("notes");

	const address = methods.register("contact.address", { deps: [lineOne.name, postcode.name] });

	return (
		<FormProvider {...methods}>
			<Dialog
				visible={visible}
				header={`${type} next of kin`}
				fixedHeight
				onCloseButtonClick={onDialogClose}
				buttons={{
					primaryButton: {
						children: `${type} next of kin`,
						onClick: methods.handleSubmit(onSubmit),
						automationId: `${automationId}-submit`,
					},
					secondaryButton: {
						children: "Cancel",
						onClick: methods.formState.isDirty ? () => setDiscardChangesVisible(true) : () => onDialogClose(),
						automationId: `${automationId}-cancel`,
					},
				}}
			>
				<FormProvider {...methods}>
					<div className={classes.dialogContent}>
						<div className={classes.sections}>
							<Name title={title} forename={forename} surname={surname} automationId={automationId} />
							<Divider />
							<RelationshipsLookup isMandatory automationId={automationId} />
							<Divider />
							<FormGroup title="Contact">
								<Address
									address={address}
									control={methods.control}
									addressLine1={lineOne}
									addressLine2={lineTwo}
									addressLine3={lineThree}
									townCity={townCity}
									county={county}
									postcode={postcode}
									countryFieldName={countryFieldName}
									automationId={automationId}
								/>
								<FormField label="Email" name={email.name}>
									<Textbox
										type={"filledDarker"}
										defaultValue={""}
										inputName={email.name}
										onChange={email.onChange}
										onBlur={email.onBlur}
										reference={email.ref}
										size="large"
										isError={useFormError(email.name).length > 0}
										automationId={`${automationId}-email`}
									/>
								</FormField>
								<div style={{ maxWidth: "300px" }}>
									<FormField label="Mobile Phone" name={mobilePhone.name}>
										<Textbox
											type={"filledDarker"}
											defaultValue={""}
											inputName={mobilePhone.name}
											onChange={mobilePhone.onChange}
											onBlur={mobilePhone.onBlur}
											reference={mobilePhone.ref}
											size="large"
											isError={useFormError(mobilePhone.name).length > 0}
											automationId={`${automationId}-mobile`}
										/>
									</FormField>
								</div>
								<AdditionalPhoneNumbers
									workPhone={workPhone}
									mainPhone={mainPhone}
									hasMainPhoneError={useFormError(mainPhone.name).length > 0}
									hasWorkPhoneError={useFormError(workPhone.name).length > 0}
									automationId={automationId}
								/>
							</FormGroup>
							<Divider />
							<FormField label="Date of birth" name={dateOfBirth.name}>
								<Controller
									name={dateOfBirth.name}
									control={methods.control}
									rules={{
										validate: {
											pastOnly: (value) => !dayjs(value).isAfter(dayjs(), "day") || dateOfBirthPastSchemaError,
										},
									}}
									render={({ field: { value, onChange, error } }) => (
										<FormDatebox
											value={value}
											onChange={onChange}
											isError={error}
											automationId={`${automationId}-dateOfBirth`}
										/>
									)}
								/>
							</FormField>
							<Divider />
							<FormField label="Notes" name={notes.name}>
								<Controller
									name={notes.name}
									control={methods.control}
									render={({ field }) => (
										<TextArea
											canGrow
											type={"filledDarker"}
											value={field.value}
											onChange={field.onChange}
											automationId={`${automationId}-notes`}
										/>
									)}
								/>
							</FormField>
						</div>
					</div>
				</FormProvider>
			</Dialog>
			<DiscardChangesDialog
				visible={discardChangesVisible}
				setVisible={setDiscardChangesVisible}
				onClose={() => {
					methods.reset({ ...addNextOfKinFormDefaultValues() });
					setDiscardChangesVisible(false);
					onDialogClose();
				}}
			/>
		</FormProvider>
	);
};
