export const addCancelAppointmentValues = () => ({
	outcome: null,
	cancellationReason: null,
});

export const transformAppointmentDataForCancel = (data, originalData) => ({
	bookingUniqueId: originalData?.booking?.bookingUniqueId,
	patientId: originalData?.patient?.patientId,
	cancellationReasonId: data?.cancellationReason?.cancellationReasonId,
	outcomeId: data?.outcome?.outcomeId,
	isCancelAssociatedBookings: data?.isCancelAssociatedBookings,
});
