import { useState } from "react";
import classes from "./RequestCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faClipboardListCheck, faDownload } from "@shfortawesome/pro-light-svg-icons";
import {
	faEllipsis as farEllipsis,
	faClipboardListCheck as farClipboardListCheck,
	faDownload as farDownload,
} from "@shfortawesome/pro-regular-svg-icons";
import {
	faEllipsis as fasEllipsis,
	faClipboardListCheck as fasClipboardListCheck,
	faDownload as fasDownload,
} from "@shfortawesome/pro-solid-svg-icons";
import { Badge, Button, Ghost, MenuButton, NavLink } from "@streets-heaver/shui2";
import clsx from "clsx";
import { MinimiseButton } from "../MinimiseButton/MinimiseButton";
import { dateTimeFormat, localFromUTC, argbToRgba } from "../../utils";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { TruncatedText } from "../TruncatedText/TruncatedText";

export const RequestCard = ({ order, style, isResultsCard, ghost, innerRef }) => {
	const [minimised, setMinimised] = useState(isResultsCard);
	const { userSecurityFlags } = useCurrentClinicians();

	return (
		<div
			className={classes.requestCard}
			style={style}
			data-testid={ghost ? "request-card-ghost" : "request-card"}
			ref={innerRef}
		>
			<div
				className={clsx(classes.header, minimised && classes.minimised)}
				onClick={() => setMinimised(!minimised)}
				style={{
					color: isResultsCard ? "var(--foregroundTwo)" : "var(--foregroundThree)",
					pointerEvents: isResultsCard ? "all" : "none",
					padding: isResultsCard ? "4px 4px 4px 16px" : "8px 60px",
				}}
			>
				{ghost ? <Ghost margin="5px 0" height={20} width="80%" /> : <TruncatedText>{order?.name}</TruncatedText>}
				{ghost ? (
					<Ghost height={20} width="60%" />
				) : (
					order?.completionDate && <p>{dateTimeFormat(localFromUTC(order?.completionDate))}</p>
				)}
				{isResultsCard && !ghost && (
					<>
						<div className={clsx(classes.actions, classes.desktopActions)}>
							<Button
								type="subtle"
								icon={{
									restIcon: faClipboardListCheck,
									hoverIcon: farClipboardListCheck,
									pressedIcon: fasClipboardListCheck,
								}}
								disabled={!userSecurityFlags.includes("orders-report")}
								onClick={(e) => e.stopPropagation()}
							>
								Report
							</Button>
							<Button
								type="subtle"
								icon={{
									restIcon: faDownload,
									hoverIcon: farDownload,
									pressedIcon: fasDownload,
								}}
								onClick={(e) => e.stopPropagation()}
								disabled={!userSecurityFlags.includes("orders-download")}
							>
								Download
							</Button>
							<MinimiseButton minimise={minimised} setMinimise={setMinimised} />
						</div>
						<div className={clsx(classes.actions, classes.mobileActions)}>
							<MenuButton
								type="subtle"
								icon={{ restIcon: faEllipsis, hoverIcon: farEllipsis, pressedIcon: fasEllipsis }}
								noChevron
								portalTarget={document.body}
								menuProps={{
									hasIcons: true,
									items: [
										{
											header: "More",
											items: [
												{
													text: "Download report",
													icon: <FontAwesomeIcon icon={faDownload} />,
													onClick: () => {
														console.log("Report Menu");
													},
												},
												{
													text: "Download results",
													icon: <FontAwesomeIcon icon={faDownload} />,
													onClick: () => {
														console.log("Results Menu");
													},
												},
											],
										},
									],
								}}
							/>
							<MinimiseButton minimise={minimised} setMinimise={setMinimised} />
						</div>
					</>
				)}
			</div>
			{!minimised && (
				<div className={classes.body}>
					{order?.results?.length > 0 &&
						order?.results?.map((result, i) => (
							<ExpandedRequestResultRow
								result={result}
								isResultsCard={isResultsCard}
								to={ActionPaths.FormHistoryResults(result?.formComponentId)}
								key={`${order?.uniqueId}-result-${i}`}
							/>
						))}
				</div>
			)}
		</div>
	);
};

export const ExpandedRequestResultRow = ({ result, isResultsCard, to, ghost }) => {
  const warningTextColour = result?.warningColour ? argbToRgba(result.warningColour) : undefined;

  return (
		<div className={classes.requestRow} data-testid={ghost ? "request-result-row-ghost" : "request-result-row"}>
			<NavLink type={isResultsCard ? "default" : "null"} to={isResultsCard ? to : undefined}>
				<p className={isResultsCard ? classes.name : classes.date}>
					{ghost ? <Ghost height={20} /> : result?.name || dateTimeFormat(result?.createDate)}
				</p>
			</NavLink>
			<p className={classes.result}>{ghost ? <Ghost width={50} height={20} /> : getResultValue(result)}</p>
			<p className={classes.range}>
				{ghost ? (
					<Ghost width={80} height={20} />
				) : (
					result?.lowLimit !== null &&
					result?.highLimit !== null &&
					`${result?.lowLimit} - ${result?.highLimit} ${result?.units ?? ""}`
				)}
			</p>
			<div className={classes.warning}>
				{ghost ? (
					<Ghost width={50} height={20} />
				) : (
					result?.warningText && (
						<Badge
							text={result?.warningText}
							type="outline"
							rounded
							style={{
								borderColor: warningTextColour,
								color: warningTextColour,
							}}
						/>
					)
				)}
			</div>
		</div>
	);
};

export const getResultValue = (requestResult) => {
	if (!requestResult) return "Result cannot be displayed";
	const isPicklist = requestResult.result?.value !== undefined && requestResult.result?.caption;
	const isMultiPicklist = Array.isArray(requestResult.result);
	const isValue = typeof requestResult.result === "string" || typeof requestResult.result === "number";
	const unitsPostfix = requestResult.units ? ` ${requestResult.units}` : "";

	if (isValue) {
		return `${requestResult.result}${unitsPostfix}`;
	}
	if (isPicklist) {
		return `${requestResult.result.caption}${unitsPostfix}`;
	}

	if (isMultiPicklist) {
		if (requestResult.result.length === 0) {
			return "-";
		} else if (requestResult.result.length === 1) {
			return `${requestResult.result[0].caption}${unitsPostfix}`;
		} else {
			const value = requestResult.result
				.map((v) => v.caption)
				.filter((v) => v !== undefined)
				.join(", ");
			if (value) {
				return `${value}${unitsPostfix}`;
			}
		}
	}

	return "Result cannot be displayed";
};
