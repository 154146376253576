export const validateNHSNumber = (nhsNum) => {
	let checkDigit = 0;
	for (let i = 10; i > 1; i--) {
		checkDigit += Number(nhsNum[10 - i]) * i;
	}
	checkDigit = 11 - (checkDigit % 11);
	checkDigit = checkDigit === 11 ? 0 : checkDigit;

	return !(checkDigit === 10 || Number(nhsNum[9]) !== checkDigit);
};
