export const addNextOfKinFormDefaultValues = () => ({
	name: {
		surname: "",
		forename: "",
		title: "",
	},
	relationship: undefined,
	contact: {
		address: {
			addressLine1: "",
			addressLine2: "",
			addressLine3: "",
			addressLine4: "",
			addressLine5: "",
			postcode: "",
			country: undefined,
		},
		email: "",
		mobilePhone: "",
		mainPhone: "",
		workPhone: "",
	},
	dateOfBirth: null,
	notes: "",
});
