import { genderOptions, notificationMethodOptions, nhsVerifyStatusOptions } from "../../globals/defaultOptions";
import { convertEmptyStringToNull, localToUTC, localFromUTC } from "../../utils";

export const transformPatientDataForForm = (data) => ({
	...data,
	identity: {
		dateOfBirth: data?.identity?.dateOfBirth !== null ? localFromUTC(data?.identity?.dateOfBirth).toDate() : null,
		gender: genderOptions.find((item) => item.label === data?.identity?.gender).value ?? genderOptions[2].value,
	},
	contact: {
		primary: {
			...data?.contact?.primary,
			notificationMethodConfirmed: data?.contact?.primary?.preferredNotificationMethod !== null,
			preferredNotificationMethod:
				data?.contact?.primary?.preferredNotificationMethod !== null
					? notificationMethodOptions.find((item) => item.api === data?.contact?.primary?.preferredNotificationMethod)
					: null,
		},
		secondary: data?.contact?.secondary,
	},
	identifiers: {
		...data?.identifiers,
		verificationStatus:
			nhsVerifyStatusOptions.find((item) => item?.value === data?.identifiers?.verificationStatus) ??
			nhsVerifyStatusOptions[2],
		nhsTraceDate:
			data?.identifiers?.nhsTraceDate !== null ? localFromUTC(data?.identifiers?.nhsTraceDate).toDate() : null,
	},
	nextOfKin:
		data?.nextOfKin?.length > 0
			? data?.nextOfKin.map((nok) => ({
					...nok,
					dateOfBirth: nok?.dateOfBirth !== null ? localFromUTC(nok?.dateOfBirth).toDate() : null,
				}))
			: [],
});

export const transformPatientDataForAdd = (data) => ({
	name: {
		title: convertEmptyStringToNull(data?.name?.primary?.title) ?? null,
		forename: convertEmptyStringToNull(data?.name?.primary?.forename) ?? null,
		surname: data?.name?.primary?.surname,
	},
	identity: {
		dateOfBirth: localToUTC(data?.identity?.dateOfBirth) ?? null,
		gender: data?.identity?.gender ?? null,
	},
	contact: {
		primary: {
			email: convertEmptyStringToNull(data?.contact?.primary?.email) ?? null,
			mobilePhone: convertEmptyStringToNull(data?.contact?.primary?.mobilePhone) ?? null,
			workPhone: convertEmptyStringToNull(data?.contact?.primary?.workPhone) ?? null,
			mainPhone: convertEmptyStringToNull(data?.contact?.primary?.mainPhone) ?? null,
			canReceiveDebtorLetters: true,
			address: {
				addressLine1: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine1) ?? null,
				addressLine2: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine2) ?? null,
				addressLine3: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine3) ?? null,
				addressLine4: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine4) ?? null,
				addressLine5: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine5) ?? null,
				postcode: convertEmptyStringToNull(data?.contact?.primary?.address?.postcode) ?? null,
				country: data?.contact?.primary?.address?.country ?? null,
			},
			preferredWrittenMethod: data?.contact?.primary?.preferredWrittenMethod ?? null,
			preferredNotificationMethod: !data?.contact?.primary?.notificationMethodConfirmed
				? null
				: notificationMethodOptions.find((item) => item.value === data?.contact?.primary?.preferredNotificationMethod)
						.api ?? null,
		},
		secondary: {
			email: null,
			mobilePhone: null,
			workPhone: null,
			mainPhone: null,
			address: null,
		},
	},
	sharedDetails: {
		registeredGP: data?.sharedDetails?.registeredGP ?? null,
		gpPractice: data?.sharedDetails?.gpPractice ?? null,
		registeredOptician: data?.sharedDetails?.registeredOptician ?? null,
		opticianPractice: data?.sharedDetails?.opticianPractice ?? null,
	},
	identifiers: {
		caseNo: convertEmptyStringToNull(data?.identifiers?.caseNo) ?? null,
		nhsNo: convertEmptyStringToNull(data?.identifiers?.nhsNo) ?? null,
		hospitalId: convertEmptyStringToNull(data?.identifiers?.hospitalId) ?? null,
		verificationStatus: data?.identifiers?.verificationStatus?.label,
		nhsTraceDate: localToUTC(data?.identifiers?.nhsTraceDate) ?? null,
	},
	nextOfKin:
		data?.nextOfKin?.length > 0
			? data?.nextOfKin?.map((nok) => ({ ...nok, dateOfBirth: localToUTC(nok?.dateOfBirth) }))
			: null,
});

export const transformPatientDataForEdit = (data, originalData, patientId) => ({
	...originalData,
	name: {
		primary: {
			...originalData?.name.primary,
			title: convertEmptyStringToNull(data?.name?.primary?.title) ?? null,
			forename: convertEmptyStringToNull(data?.name?.primary?.forename) ?? null,
			surname: convertEmptyStringToNull(data?.name?.primary?.surname) ?? null,
		},
		secondary: originalData?.name?.secondary ?? null,
	},
	identity: {
		...originalData?.identity,
		dateOfBirth: localToUTC(data?.identity?.dateOfBirth) ?? null,
		gender: data?.identity?.gender ?? originalData?.identity?.gender ?? null,
	},
	contact: {
		primary: {
			...originalData?.contact?.primary,
			email: convertEmptyStringToNull(data?.contact?.primary?.email) ?? null,
			mobilePhone: convertEmptyStringToNull(data?.contact?.primary?.mobilePhone) ?? null,
			workPhone: convertEmptyStringToNull(data?.contact?.primary?.workPhone) ?? null,
			mainPhone: convertEmptyStringToNull(data?.contact?.primary?.mainPhone) ?? null,
			address: {
				addressLine1: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine1) ?? null,
				addressLine2: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine2) ?? null,
				addressLine3: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine3) ?? null,
				addressLine4: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine4) ?? null,
				addressLine5: convertEmptyStringToNull(data?.contact?.primary?.address?.addressLine5) ?? null,
				postcode: convertEmptyStringToNull(data?.contact?.primary?.address?.postcode) ?? null,
				country: data?.contact?.primary?.address?.country ?? null,
			},
			preferredWrittenMethod: data?.contact?.primary?.preferredWrittenMethod ?? null,
			preferredNotificationMethod: !data?.contact?.primary?.notificationMethodConfirmed
				? null
				: notificationMethodOptions.find((item) => item.value === data?.contact?.primary?.preferredNotificationMethod)
						.api ?? null,
		},
		secondary: originalData?.contact?.secondary ?? null,
	},
	sharedDetails: {
		...originalData?.sharedDetails,
		registeredGP: data?.sharedDetails?.registeredGP ?? null,
		gpPractice: data?.sharedDetails?.gpPractice ?? null,
		registeredOptician: data?.sharedDetails?.registeredOptician ?? null,
		opticianPractice: data?.sharedDetails?.opticianPractice ?? null,
	},
	identifiers: {
		...originalData?.identifiers,
		caseNo: convertEmptyStringToNull(data?.identifiers?.caseNo) ?? null,
		nhsNo: convertEmptyStringToNull(data?.identifiers?.nhsNo) ?? null,
		verificationStatus: data?.identifiers?.verificationStatus?.label ?? null,
		nhsTraceDate: localToUTC(data?.identifiers?.nhsTraceDate) ?? null,
		hospitalId: convertEmptyStringToNull(data?.identifiers?.hospitalId) ?? null,
		patientId: parseInt(patientId),
	},
	nextOfKin:
		data?.nextOfKin?.length > 0
			? data?.nextOfKin?.map((nok) => ({ ...nok, dateOfBirth: localToUTC(nok?.dateOfBirth) }))
			: null,
});
