import { Outlet } from "react-router";
import { MotionConfig } from "framer-motion";
import Frame from "./Frame";
import classes from "./Layout.module.scss";
import { VersionProvider } from "@streets-heaver/core";
import { isTesting } from "../../globals/constants";
import { ClinicianProvider } from "../../providers/ClinicianProvider";
import { SelectedBookingProvider } from "../../providers/SelectedBookingProvider";

function Layout() {
	return (
		<MotionConfig reducedMotion={isTesting ? "always" : "user"}>
			<div style={isTesting ? { transitionDelay: "0s !important", transitionDuration: "0s !important" } : {}}>
				<VersionProvider>
					<ClinicianProvider>
						<SelectedBookingProvider>
							<div className={classes.outerContainer}>
								<Frame>
									<div id="contentContainer" className={classes.innerContainer}>
										<Outlet />
									</div>
								</Frame>
							</div>
						</SelectedBookingProvider>
					</ClinicianProvider>
				</VersionProvider>
			</div>
		</MotionConfig>
	);
}

export default Layout;
