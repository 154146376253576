export const getMyPatientsColumns = (title) => {
	switch (title) {
		default:
		case "Outpatient":
			return [
				{
					displayName: "Patient",
					accessorKey: "patientNameAndId",
				},
				{
					displayName: "Location",
					accessorKey: "location",
				},
				{
					displayName: "Start time",
					accessorKey: "startTime",
				},
				{
					displayName: "End time",
					accessorKey: "endTime",
				},
				{
					displayName: "Start date",
					accessorKey: "startDate",
				},
				{
					displayName: "End date",
					accessorKey: "endDate",
				},
				{
					displayName: "Duration",
					accessorKey: "duration",
				},
				{
					displayName: "Contact type",
					accessorKey: "contactType",
				},
			];
		case "Inpatient":
			return [
				{
					displayName: "Patient",
					accessorKey: "patientNameAndId",
				},
				{
					displayName: "Procedure(s)",
					accessorKey: "procedure",
				},
				{
					displayName: "Admission",
					accessorKey: "startDateTime",
				},
				{
					displayName: "Discharge",
					accessorKey: "endDateTime",
				},
				{
					displayName: "Booking status",
					accessorKey: "status",
				},
			];
		case "Surgical":
			return [
				{
					displayName: "Patient",
					accessorKey: "patientNameAndId",
				},
				{
					displayName: "Procedure(s)",
					accessorKey: "procedure",
				},
				{
					displayName: "Start time",
					accessorKey: "startTime",
				},
				{
					displayName: "End time",
					accessorKey: "endTime",
				},
				{
					displayName: "Start date",
					accessorKey: "startDate",
				},
				{
					displayName: "End date",
					accessorKey: "endDate",
				},
			];
	}
};

export const getDefaultMyPatientsColumns = (title) => {
	switch (title) {
		default:
		case "outpatient":
			return ["Patient", "Location", "Start date", "Start time", "Duration", "Contact type"];
		case "inpatient":
			return ["Patient", "Procedure(s)", "Admission", "Discharge", "Booking status"];
		case "surgical":
			return ["Patient", "Procedure(s)", "Start time"];
	}
};

export const mobileMyPatientsColumns = [
	{
		displayName: "Patient",
		accessorKey: "mobileMyPatientsCell",
	},
];

export const desktopOrdersColumns = [
	{
		displayName: "Orders",
		accessorKey: "patientNameAndId",
	},
	{
		displayName: "Service request",
		accessorKey: "request",
	},
	{
		displayName: "Seen",
		accessorKey: "isRead",
	},
	{
		displayName: "Results",
		accessorKey: "results",
	},
];

export const mobileOrdersColumns = [
	{
		displayName: "Orders",
		accessorKey: "mobileOrderCell",
	},
];
