import { ErrorDisplay } from "@streets-heaver/shui2";
import { Outlet } from "react-router";
import { ContentSkeleton } from "../../components/ContentSkeleton/ContentSkeleton";
import { usePageError } from "../../utils/hooks/usePageError";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { useVersion } from "@streets-heaver/core";

export const Page = () => {
	const error = usePageError();
	const { setupStatus } = useCurrentClinicians();
	const { dataSourceVersion } = useVersion();

	if (setupStatus === "pending" || (setupStatus === "success" && !dataSourceVersion)) return <ContentSkeleton />;

	if (error)
		return (
			<ErrorDisplay title={`${error?.title ?? "Unexpected Application Error"}`}>
				{(error?.detail || error?.message) ?? "Unknown error. Please contact an administrator."}
			</ErrorDisplay>
		);

	return <Outlet />;
};
