export const bookingStatus = {
	Due: [0, 1],
	Waiting: [102],
	"In progress": [2],
	Completed: [3],
	"Cancelled/DNA": [100, 101],
};

export const getStatusTextUpdate = (status, bookingType) => {
	if (bookingType === "IP") {
		if (status?.toLowerCase() === "attended") return "Patient has been admitted";
		if (status?.toLowerCase() === "completed") return "Patient has been discharged";
	}

	switch (status?.toLowerCase()) {
		default:
		case "unknown":
			return "Status is unknown";
		case "booked":
			return "Patient is due";
		case "arrived":
			return "Patient has arrived";
		case "attended":
			return "Appointment in progress";
		case "completed":
			return "Appointment completed";
		case "cancelled":
			return "Appointment cancelled";
		case "dna":
			return "Patient did not attend";
	}
};

export const getBookingStatus = (status, bookingType) => {
	if (bookingType?.toLowerCase() === "inpatient") {
		if (status?.toLowerCase() === "attended") return "Admitted";
		if (status?.toLowerCase() === "completed") return "Discharged";
	}
	return status;
};
