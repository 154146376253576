import { Button } from "@streets-heaver/shui2";
import classes from "./MobileOrderCell.module.scss";
import { PatientNameAndId } from "../PatientNameAndID/PatientNameAndID";
import { PatientSeenRequest, ServiceRequests } from "../BadgeCells/BadgeCells";
import { MinimiseButton } from "../../../MinimiseButton/MinimiseButton";
import { ServiceRequestRow } from "../ServiceRequestRow/ServiceRequestRow";
import { faClipboardListCheck, faDownload } from "@shfortawesome/pro-light-svg-icons";
import {
	faCheck,
	faClipboardListCheck as farClipboardListCheck,
	faDownload as farDownload,
} from "@shfortawesome/pro-regular-svg-icons";
import {
	faClipboardListCheck as fasClipboardListCheck,
	faDownload as fasDownload,
} from "@shfortawesome/pro-solid-svg-icons";
import { ActionPaths } from "../../../../layouts/Layout/actionPaths";
import { SwipeInteraction } from "../../../SwipeInteraction/SwipeInteraction";
import { useCurrentClinicians } from "../../../../api/hooks/useClinicians";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { useUpdateOrderStatus } from "../../../../api/hooks/useUpdateOrderStatus";

export const MobileOrderCell = ({ row, resultCallback }) => {
	const { userSecurityFlags } = useCurrentClinicians();
	const hasSomeSent = row?.original?.orders.some((request) => request?.status === "Sent");
	const hasSomeSeen = row?.original?.orders.some((request) => request?.status === "Seen");
	const { mutate: markAsSeen } = useUpdateOrderStatus(!hasSomeSent ? "sent" : "seen");
	const navigate = useNavigate();

	return (
		!row?.original?.filler && (
			<SwipeInteraction
				enabled={(hasSomeSeen || hasSomeSent) && userSecurityFlags.includes("orders-markseen")}
				actionText={!hasSomeSent ? "Unseen" : "Seen"}
				actionIcon={faCheck}
				onSwipeAction={() =>
					markAsSeen(
						row?.original?.orders
							.filter((request) => request?.status === (!hasSomeSent ? "Seen" : "Sent"))
							.map((order) => order.orderId),
					)
				}
			>
				<div className={clsx(classes.orderCell, classes.addedPadding)} onClick={() => row.toggleExpanded()}>
					<div className={classes.topCell}>
						<div className={classes.info}>
							<div className={classes.header}>
								<PatientNameAndId details={row?.original} isOrders />
							</div>
							<div className={classes.row}>
								<PatientSeenRequest allSeen={row?.original?.allSeen} orders={row?.original?.orders} />
								<ServiceRequests requests={row?.original?.orders} size="medium" />
							</div>
						</div>
						<MinimiseButton minimise={!row?.getIsExpanded()} setMinimise={() => row.toggleExpanded()} />
					</div>
					{row?.getIsExpanded() && (
						<div className={classes.bottomCell}>
							{row?.original?.orders?.map((order, orderIndex) => (
								<ServiceRequestRow
									key={`${row?.original?.orderUniqueId}-${orderIndex}`}
									request={order}
									resultCallback={() => ActionPaths.ReviewResults(row?.original?.patientId)}
								/>
							))}
							<div className={classes.actions} onClick={(e) => e.stopPropagation()}>
								{row?.original?.anyResults && (
									<Button
										type="subtle"
										icon={{
											restIcon: faClipboardListCheck,
											hoverIcon: farClipboardListCheck,
											pressedIcon: fasClipboardListCheck,
										}}
										onClick={(e) => {
											e.stopPropagation();
											navigate(resultCallback?.(row?.original));
										}}
									>
										Review results
									</Button>
								)}
								<Button
									type="subtle"
									icon={{
										restIcon: faDownload,
										hoverIcon: farDownload,
										pressedIcon: fasDownload,
									}}
									onClick={(e) => {
										e.stopPropagation();
										console.log("Download");
									}}
									disabled={!userSecurityFlags.includes("orders-download")}
								>
									Download
								</Button>
							</div>
						</div>
					)}
				</div>
			</SwipeInteraction>
		)
	);
};
