import classes from "./PatientOrders.module.scss";
import { Divider } from "@streets-heaver/shui2";
import { ServiceRequestRow } from "../../infoTable/cellComponents";
import { ActionPaths } from "../../../layouts/Layout/actionPaths";
import { useParams } from "react-router";
import { usePatientOrdersWidget } from "../../../api/hooks";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { Widget } from "../Widget/Widget";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";

export const PatientOrders = ({ patientName, hasPermission }) => {
	const { patientId } = useParams();
	const query = usePatientOrdersWidget(patientId, 10, hasPermission);

	const serviceRequestCount = query?.data?.pages[0]?.totalServiceRequests ?? 0;

	if (!hasPermission) {
		return (
			<Widget>
				<NoAccessMessage />
			</Widget>
		);
	}

	return (
		<Widget>
			<Widget.Title>
				Orders for {patientName ?? "Patient"}
				<span className={classes.divider} />
				<span className={classes.count}>{serviceRequestCount}</span>
			</Widget.Title>
			{returnPatientOrders(query, serviceRequestCount, patientId)}
		</Widget>
	);
};

const returnPatientOrders = (query, serviceRequestCount, patientId) => {
	if (query.loading)
		return (
			<Widget.Locked
				subtitle="Loading patient orders..."
				icon={faClipboardListCheck}
				colour="var(--foregroundBrandOne)"
			/>
		);

	if (query.error)
		return (
			<Widget.Locked
				subtitle="Error fetching order for this patient."
				icon={faClipboardListCheck}
				colour="var(--foregroundBrandOne)"
			/>
		);

	if (serviceRequestCount === 0)
		return (
			<Widget.Locked
				subtitle="No orders available for this patient."
				icon={faClipboardListCheck}
				colour="var(--foregroundBrandOne)"
			/>
		);

	return (
		<div
			className={classes.serviceRequestList}
			onScroll={(e) => {
				if (
					e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 64 &&
					!query.isLoading &&
					!query.isFetching &&
					query.hasNextPage
				) {
					query.fetchNextPage();
				}
			}}
		>
			{query.data?.pages?.map((page, pageI) =>
				page?.data?.map((serviceRequest, i) => (
					<div
						key={`patientView-orders-${i}`}
						style={{
							backgroundColor: "var(--backgroundTwo)",
							borderRadius:
								pageI < 1 && i < 1
									? "8px 8px 0 0"
									: pageI === query.data?.pages?.length - 1 && i === page?.data?.length - 1
										? "0 0 8px 8px"
										: "unset",
						}}
					>
						<ServiceRequestRow
							request={serviceRequest}
							resultCallback={() => "../orders/" + ActionPaths.ReviewResults(patientId)}
							showShortButtons
						/>
						{!(pageI === query.data?.pages?.length - 1 && i === page?.data?.length - 1) && <Divider />}
					</div>
				)),
			)}
		</div>
	);
};
