import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";

export const useNextAppointmentWidget = (patientId) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	const query = useQuery({
		queryKey: ["nextAppointmentWidget", currentClinician, patientId],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patient/${patientId}/widget/appointment`,
				{},
				"v2",
			);

			if (data?.request?.response) throw new Error("Getting next appointment for patient failed");
			return data;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId,
	});

	return query;
};
