import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";

export const usePrintList = (type) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	return useMutation({
		mutationKey: ["printList"],
		mutationFn: (data) => {
			return makeApiRequest(`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/print/${type}`, {
				method: "post",
				body: data,
			});
		},
	});
};
