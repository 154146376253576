import { Divider, TextArea } from "@streets-heaver/shui2";
import { BookingItem, EpisodeOfCareItem, PatientItem } from "@streetsheaver/compucore";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { Purchaser } from "./Sections/Purchaser/Purchaser";
import classes from "./AppointmentForm.module.scss";
import { EditBooking } from "./Sections/EditBooking/EditBooking";
import { Cancel } from "./Sections/Cancel/Cancel";

export const EditAppointmentFormContent = ({ patient, episodeOfCare, booking, automationId }) => {
	const methods = useFormContext();
	const isCancelledOrDNA = booking?.status === "Cancelled" || booking?.status === "DNA";

	const notes = methods.register("notes");

	return (
		<div className={classes.appointmentForm}>
			<div className={classes.header}>
				<div className={classes.patient}>
					<PatientItem
						data={patient}
						automationId={`${automationId}-patientItem`}
						patientLinkPrefix="../patient-area/"
					/>
				</div>
				<EpisodeOfCareItem data={episodeOfCare} automationId={`${automationId}-eocItem`} />
				<BookingItem data={booking} automationId={`${automationId}-bookingItem`} />
			</div>
			<Divider />
			<EditBooking booking={booking} automationId={automationId} />
			<Divider />
			<Purchaser isEdit automationId={automationId} />
			<Divider />
			{isCancelledOrDNA && (
				<>
					<Cancel isEdit automationId={automationId} />
					<Divider />
				</>
			)}
			<FormField label="Notes" name={notes.name}>
				<Controller
					control={methods.control}
					name={notes.name}
					render={({ field: { onChange, value } }) => (
						<TextArea
							type="filledDarker"
							size="large"
							value={value}
							onChange={onChange}
							automationId={`${automationId}-notes`}
						/>
					)}
				/>
			</FormField>
		</div>
	);
};
