import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { ErrorDisplay, Spinner } from "@streets-heaver/shui2";
import { FormWrapper, NoAccessPage } from "../../components";
import { useCurrentClinicians, usePatientForEdit } from "../../api/hooks";
import classes from "./EditPatient.module.scss";
import { EditPatientForm } from "./EditPatientForm";
import { transformPatientDataForForm } from "../AddPatient/transformPatientData";

export const EditPatient = () => {
	const { patientId } = useParams();
	const { userSecurityFlags } = useCurrentClinicians();
	const { data, isLoading } = usePatientForEdit(patientId, !!patientId);
	const [formattedData, setFormattedData] = useState();

	useEffect(() => {
		if (data) setFormattedData(transformPatientDataForForm(data));
	}, [data]);

	if (!userSecurityFlags.includes("patient-editpatient")) {
		return <NoAccessPage />;
	}

	if (patientId && formattedData && !isLoading) return <EditPatientForm data={formattedData} patientId={patientId} />;

	return (
		<FormWrapper>
			<FormWrapper.Body>
				<div className={classes.formContent}>
					{!patientId ? (
						<ErrorDisplay
							title="No patient selected"
							primaryButtonProps={{ children: "Back to dashboard", navigation: { to: ".." } }}
						>
							There is no patient selected to edit. Please navigate to a patient directly in order to edit.
						</ErrorDisplay>
					) : (
						<Spinner size="huge" text="Loading patient details" />
					)}
				</div>
			</FormWrapper.Body>
		</FormWrapper>
	);
};
