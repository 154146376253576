import { useCancellationReasonsLookup } from "../../../api/hooks";
import { cancellationReasonSchemaError } from "../../../globals/messages";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const CancellationReasonsLookup = ({
	isMandatory,
	automationId,
	label = "Cancellation reason",
	name = "cancellationReason",
}) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useCancellationReasonsLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			rules={isMandatory && { validate: { isMandatory: (value) => value || cancellationReasonSchemaError } }}
			query={query}
			isMandatory={isMandatory}
			automationId={automationId}
		/>
	);
};
