import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "./useClinicians";
import toast from "react-hot-toast";

export const useUpdateOrderStatus = (status) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateOrderStatus"],
		mutationFn: (orderId) => {
			const idArray = orderId?.length ? orderId : [orderId];
			return makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/order/status?updateStatus=${status}&${idArray
					.map((id) => `orderIds=${id}`)
					.join("&")}`,
				{
					method: "put",
				},
				"v2",
			);
		},
		onSettled: async () => {
			return await queryClient.invalidateQueries({ queryKey: ["orders"] });
		},
		onError: () =>
			toast.error({
				title: `Error marking as ${status}`,
				subtitle: `Failed to mark order as ${status}.`,
			}),
	});
};
