import classes from "./AbnormalResults.module.scss";
import { ActionPaths } from "../../../layouts/Layout/actionPaths";
import { Link } from "../../Link/Link";
import { ExpandedRequestResultRow } from "../../RequestCard/RequestCard";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { useAbnormalResults } from "../../../api/hooks";
import { useEffect, useRef } from "react";
import { Widget } from "../Widget/Widget";
import { faRectangleList } from "@shfortawesome/pro-duotone-svg-icons";

export const AbnormalResults = ({ patientId, hasPermission }) => {
	const { data, isFetching, fetchNextPage: fetchMoreResults } = useAbnormalResults(patientId, 5);
	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((elements) => {
			elements.forEach((element) => {
				if (element.isIntersecting && patientId) {
					fetchMoreResults();
				}
			});
		});

		if (endOfListRef?.current !== null) {
			observer.observe(endOfListRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [endOfListRef, fetchMoreResults, patientId]);

	if (!hasPermission) {
		return (
			<Widget>
				<NoAccessMessage />
			</Widget>
		);
	}

	return (
		<Widget>
			<div className={classes.AbnormalResults}>
				<div className={classes.Header}>
					<Widget.Title>Latest abnormal results</Widget.Title>
					<Link text="View orders" icon to={`../orders/${ActionPaths.ReviewResults(patientId)}`} />
				</div>

				<div className={classes.Table}>
					<div className={classes.TableBody}>
						{data?.length > 0 ? (
							data?.map((result, i) => (
								<ExpandedRequestResultRow
									key={i}
									result={result}
									isResultsCard
									to={`../orders/${ActionPaths.ReviewResults(patientId)}/${ActionPaths.FormHistoryResults(
										result.formComponentId,
									)}`}
								/>
							))
						) : (
							<Widget.Locked
								subtitle={"No abnormal results for this patient."}
								icon={faRectangleList}
								colour="var(--foregroundBrandOne)"
							/>
						)}
						<div ref={endOfListRef} />
						{isFetching && <ExpandedRequestResultRow isResultsCard ghost />}
					</div>
				</div>
			</div>
		</Widget>
	);
};
