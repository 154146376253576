import { NavLink } from "@streets-heaver/shui2";
import { Radio } from "@streets-heaver/shui2/inputs";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { genderOptions } from "../../../../../globals/defaultOptions";
import { FormDatebox } from "../../../FormDatebox/FormDatebox";
import { dateOfBirthPastSchemaError, genderSchemaError } from "../../../../../globals/messages";

export const Identity = ({ automationId }) => {
	const form = useFormContext();

	const dateOfBirthName = "identity.dateOfBirth";
	const genderName = "identity.gender";

	return (
		<div data-section id="identity">
			<FormGroup title="Identity">
				<FormField label="Date of birth" name={dateOfBirthName}>
					<Controller
						name={dateOfBirthName}
						control={form.control}
						rules={{
							validate: {
								pastOnly: (value) => !dayjs(value).isAfter(dayjs(), "day") || dateOfBirthPastSchemaError,
							},
						}}
						render={({ field: { value, onChange, error, ref: fieldRef } }) => (
							<FormDatebox
								value={value}
								onChange={onChange}
								isError={error}
								portalTarget={document.body}
								automationId={`${automationId}-dateOfBirth`}
								ref={fieldRef}
							/>
						)}
					/>
				</FormField>

				<FormField
					label="Gender (stated)"
					helperText={
						<>
							The patient&apos;s gender is linked to a protected (noneditable) gender code table populated with values
							from the{" "}
							<NavLink
								to="https://www.datadictionary.nhs.uk/attributes/person_stated_gender_code.html"
								style={{ fontSize: "12px" }}
								newTab
							>
								NHS Data Dictionary
							</NavLink>
							.
						</>
					}
					isMandatory
					name={genderName}
				>
					<Controller
						name={genderName}
						control={form.control}
						rules={{ required: genderSchemaError }}
						render={({ field: { value, onChange, ref: fieldRef }, fieldState: { error } }) => (
							<Radio
								options={genderOptions}
								value={value}
								onChange={(e) => onChange(e)}
								isError={error}
								automationId={`${automationId}-gender`}
								ref={(ref) => {
									fieldRef({
										focus: () => ref.children[0].focus(),
									});
								}}
							/>
						)}
					/>
				</FormField>
			</FormGroup>
		</div>
	);
};
