import classes from "./EventItem.module.scss";
import { Button, Avatar, NavLink } from "@streets-heaver/shui2";
import {
	faCalendarDays as faCalendarDaysSolid,
	faPen as faPenSolid,
	faTrash as faTrashSolid,
} from "@shfortawesome/pro-solid-svg-icons";
import {
	faCalendarDays as faCalendarDaysRegular,
	faPen as faPenRegular,
	faTrash as faTrashRegular,
} from "@shfortawesome/pro-regular-svg-icons";
import { faCalendarDays, faCalendarRange, faSyringe, faPen, faNote, faTrash } from "@shfortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	formatNameAsInitials,
	formatNameAsReversed,
	addToCalendarIndividualBooking,
	getBookingColour,
	getAppointmentType,
	localFromUTC,
} from "../../utils";
import { EventItemCompact } from "./EventItemCompact/EventItemCompact";
import { TimeDisplay } from "../TimeDisplay/TimeDisplay";
import { TruncatedArray } from "../TruncatedArray/TruncatedArray";
import { InfoRow } from "../InfoRow/InfoRow";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useDataSources, usePreviewPanel, useScreenSizeClass, ScreenSize } from "@streetsheaver/compucore";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { useState } from "react";

export const EventItem = ({
	ghost,
	appointment,
	height = 66,
	compact = false,
	onClick,
	setSelectedBooking,
	isSelected,
	automationId = "event-item",
}) => {
	const { currentDataSource } = useDataSources();
	const { setIsSheetMaximised, setIsVisible: setPreviewPanelVisible } = usePreviewPanel();
	const currentDateTime = new Date();
	const past = currentDateTime > appointment?.end;
	const type = appointment?.type;
	const isSuspension = type === "SUS";
	const isReservation = type === "RES";
	const isPrivate = type === "PRIV";
	const isNotAppointment = isSuspension || isReservation || isPrivate;
	const onItemClick = isNotAppointment ? undefined : onClick;
	const width = useScreenSizeClass();
	const navigate = useNavigate();
	const [showActions, setShowActions] = useState(false);

	return compact ? (
		<EventItemCompact
			ghost={ghost}
			onClick={onClick}
			appointment={appointment}
			height={height}
			automationId={automationId}
			isSelected={isSelected}
		/>
	) : (
		<div
			data-testid={automationId}
			onMouseEnter={() => {
				setShowActions(true);
			}}
			onMouseLeave={() => {
				setShowActions(false);
			}}
			onClick={onItemClick}
			onKeyDown={(e) => {
				if (e.key === "Enter") onItemClick();
			}}
			tabIndex={onItemClick ? 0 : -1}
			className={clsx(classes.EventItem, isSelected && classes.SelectedEventItem, classes[type])}
			style={{
				minHeight: "66px",
				cursor: !onItemClick ? "initial" : "pointer",
			}}
		>
			<div className={classes.Appointment}>
				<div className={classes.Time} data-testid="appointment-time-details">
					<TimeDisplay
						start={localFromUTC(appointment?.start)}
						end={localFromUTC(appointment?.end)}
						column
						status={appointment.statusEnum}
					/>
				</div>
				<div className={classes.Status} style={{ backgroundColor: getBookingColour(type, past), minHeight: height }} />
				<div className={classes.Info}>
					<InfoRow
						iconSize="largePaddingless"
						icon={
							<Avatar
								ghost={ghost}
								initials={formatNameAsInitials(appointment?.forename, appointment?.surname)}
								image={appointment?.pictureUrl}
								size={20}
							/>
						}
						info={
							<div className={classes.PatientRow}>
								<p>{formatNameAsReversed(appointment?.forename, appointment?.surname, appointment?.title)}</p>
								<NavLink
									type={"default"}
									to={`/${currentDataSource?.DataSourceId}/patient-area/${appointment?.patientId}`}
									canCopy
								>
									{appointment?.patientPrimaryId}
								</NavLink>
							</div>
						}
						ghost={ghost}
						hide={isNotAppointment || height < 23}
					/>
					<InfoRow info={"Private"} hide={!isPrivate} isBold isCentred />
					<InfoRow info={`Suspension: ${appointment?.suspensionReason}`} hide={!isSuspension} isBold isCentred />
					<InfoRow info={"Reservation"} hide={!isReservation} isBold isCentred />
					<InfoRow
						info={<p className={classes.privateMessage}>You do not have permission to view this booking.</p>}
						hide={!isPrivate}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faSyringe} />}
						info={<TruncatedArray list={appointment?.procedures} name="procedures" />}
						ghost={ghost}
						hide={appointment?.procedures?.length < 1 || isNotAppointment}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faCalendarRange} />}
						info="Follow up"
						hide={!appointment?.isFollowUp}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faNote} />}
						info={
							<div className={classes.NotesButton}>
								<Button
									size="smaller"
									type="transparent"
									onClick={(e) => {
										e.stopPropagation();
										setSelectedBooking({
											id: appointment?.bookingUniqueId,
											type: getAppointmentType(type),
											overrideTab: "Notes",
										});
										setPreviewPanelVisible(true);
										setIsSheetMaximised(true);
									}}
								>
									Notes
								</Button>
							</div>
						}
						ghost={ghost}
						hide={!appointment?.hasNotes}
						style={{ gap: "unset" }}
					/>
				</div>
			</div>

			{(showActions || width <= ScreenSize.TabletLandscape) && !isPrivate && (
				<div
					className={classes.Actions}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Button
						size={"large"}
						type="transparent"
						colour="grey"
						icon={{ restIcon: faCalendarDays, hoverIcon: faCalendarDaysRegular, pressedIcon: faCalendarDaysSolid }}
						onClick={(e) => {
							e.preventDefault();
							addToCalendarIndividualBooking({ ...appointment });
						}}
						tooltipText="Add to calendar"
						tooltipWidth={120}
						hasBackground={true}
					/>
					{appointment?.type === "OP" && (
						<>
							<Button
								size={"large"}
								type="transparent"
								colour="grey"
								icon={{ restIcon: faPen, hoverIcon: faPenRegular, pressedIcon: faPenSolid, selectedIcon: faPenSolid }}
								onClick={() => navigate(ActionPaths.EditAppointmentPreselected(appointment?.bookingUniqueId))}
								tooltipText="Edit Appointment"
								tooltipWidth={120}
								hasBackground={true}
							/>
							<Button
								size={"large"}
								type="transparent"
								colour="grey"
								icon={{
									restIcon: faTrash,
									hoverIcon: faTrashRegular,
									pressedIcon: faTrashSolid,
									selectedIcon: faTrashSolid,
								}}
								onClick={() => navigate(ActionPaths.CancelAppointmentPreselected(appointment?.bookingUniqueId))}
								tooltipText="Cancel Appointment"
								tooltipWidth={120}
								hasBackground={true}
							/>
						</>
					)}
					{appointment?.videoConsultLink && (
						<Button type={"primary"} size={"large"} navigation={{ to: appointment?.videoConsultLink }}>
							Join
						</Button>
					)}
				</div>
			)}
		</div>
	);
};
