import clsx from "clsx";
import classes from "./PatientHeader.module.scss";
import { Persona } from "@streets-heaver/shui2";
import { formatNameAsInitials, formatNameAsReversed, argbToRgba, ageFromDate, shortDateFormat } from "../../utils";
import { useDataSources } from "@streetsheaver/compucore";

export const PatientHeader = ({ ghost = false, patient, isRounded = false, automationId }) => {
	const { currentDataSource } = useDataSources();

	return (
		<div className={clsx(classes.Banner, isRounded && classes.Rounded)} data-testid={automationId}>
			<Persona
				ghost={ghost}
				icon={{
					type: "avatar",
					props: {
						initials: formatNameAsInitials(patient?.forename, patient?.surname),
						image: patient?.profilePictureUrl,
						size: 64,
						badgeProps: {
							ghost: !patient?.genderColour,
							solidColour: patient?.genderColour && argbToRgba(patient?.genderColour),
						},
					},
				}}
				primaryText={formatNameAsReversed(patient?.forename, patient?.surname, patient?.title)}
				number={{
					text: patient?.patientPrimaryId,
					link: `/${currentDataSource?.DataSourceId}/patient-area/${patient?.patientId}`,
					canCopy: true,
				}}
				secondaryText={
					(patient?.bornDate ?? patient?.dateOfBirth) &&
					`${shortDateFormat(patient?.bornDate ?? patient?.dateOfBirth)} (${ageFromDate(
						patient?.bornDate ?? patient?.dateOfBirth,
					)})`
				}
				tertiaryText={patient?.address ?? patient?.addressLine1}
				quaternaryText={patient?.preferredContact}
				customWidth="100%"
			/>
		</div>
	);
};
