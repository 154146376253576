import { useState } from "react";
import { BookingLookup, PatientLookup } from "@streetsheaver/compucore";
import { usePatientsLookup, useBookingsLookup } from "../../../../../api/hooks";

export const PatientBookingSelection = ({
	selectedPatient,
	setSelectedPatient,
	selectedBooking,
	setSelectedBooking,
	automationId,
}) => {
	return (
		<>
			<PatientSelection
				selectedPatient={selectedPatient}
				setSelectedPatient={setSelectedPatient}
				automationId={automationId}
			/>
			<BookingSelection
				selectedBooking={selectedBooking}
				setSelectedBooking={setSelectedBooking}
				selectedPatient={selectedPatient}
				automationId={automationId}
			/>
		</>
	);
};

const PatientSelection = ({ selectedPatient, setSelectedPatient, automationId }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const patientsQuery = usePatientsLookup(searchTerm);

	return (
		<PatientLookup
			field={{ label: "Patient", isMandatory: true }}
			selected={selectedPatient}
			patientLinkPrefix={"../patient-area/"}
			setSelected={setSelectedPatient}
			dropdownMaxHeight={"calc(80vh - 190px)"}
			searchQueryData={patientsQuery}
			onSearchTermChange={setSearchTerm}
			automationId={`${automationId}-patient-lookup`}
		/>
	);
};

const BookingSelection = ({ selectedBooking, setSelectedBooking, selectedPatient, automationId }) => {
	const bookingsQuery = useBookingsLookup(selectedPatient?.patientId);

	return (
		<BookingLookup
			field={{ label: "Booking" }}
			selected={selectedBooking}
			setSelected={setSelectedBooking}
			dropdownMaxHeight={"calc(80vh - 190px)"}
			disabled={!selectedPatient}
			searchQueryData={bookingsQuery}
			automationId={`${automationId}-booking-lookup`}
		/>
	);
};
