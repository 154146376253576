import { Controller } from "react-hook-form";
import { FormDatebox } from "../../../FormDatebox/FormDatebox";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { CliniciansLookup, DiagnosesLookup, PatientsLookup, ReferrersLookup } from "../../../lookups";
import { referralDatePastSchemaError } from "../../../../../globals/messages";
import dayjs from "dayjs";

export const NewPatient = ({ automationId }) => {
	const referralDateName = "referralDate";

	return (
		<FormGroup>
			<PatientsLookup isMandatory automationId={automationId} />
			<FormField label="Referral date" name={referralDateName}>
				<Controller
					name={referralDateName}
					rules={{
						validate: {
							futureReferral: (value) => !dayjs(value).isAfter(dayjs(), "day") || referralDatePastSchemaError,
						},
					}}
					render={({ field: { value, onChange, error, ref } }) => (
						<FormDatebox
							value={value}
							onChange={onChange}
							isError={error}
							portalTarget={document.body}
							automationId={`${automationId}-referralDate`}
							ref={ref}
						/>
					)}
				/>
			</FormField>
			<ReferrersLookup automationId={automationId} />
			<CliniciansLookup label="Lead case holder" name={"caseHolder"} automationId={automationId} />
			<DiagnosesLookup automationId={automationId} />
		</FormGroup>
	);
};
